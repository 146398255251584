<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <parte-trabajo-add-form
          :idsistema="routeQuery.idsistema"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteTrabajoAddForm from './components/ParteTrabajoAddForm'
import Data from './ParteTrabajoAddData'
import { get, sync } from 'vuex-pathify'
import { currentDateTime, toDateTime } from '@/utils/date'
import { downloadParteTrabajo, downloadTablasGlobales } from '@/sync/download'

export default {
  components: { ParteTrabajoAddForm },
  mixins: [formPageMixin],
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdalmacen: get('usuario/idalmacen'),
    usuarioUltimaDescargaGlobales: sync('usuario/ultimaDescargaGlobales'),
  },
  async created () {
    // routeQuery.idsistema: crear un parte desde sistema
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Nuevo parte de trabajo'
    },
    async submitForm () {
      this.$loading.showManual('Creando parte de trabajo...')
      let parteCreado = null
      try {
        parteCreado = await Data.insert(this, this.formData, this.usuarioIdtecnico)
      } finally {
        this.$loading.hide()
      }
      const resDescargarParte = await this.$alert.showConfirm(
        `Se ha creado el parte de trabajo Nº${parteCreado.idparte_trabajo} ¿Deseas descargarlo ahora?`
      )
      if (resDescargarParte) {
        const ayer = Date.now() - (24 * 60 * 60 * 1000)
        if (!this.usuarioUltimaDescargaGlobales || toDateTime(this.usuarioUltimaDescargaGlobales) < ayer) {
          await downloadTablasGlobales(this)
          this.usuarioUltimaDescargaGlobales = currentDateTime()
        }
        await downloadParteTrabajo(this, parteCreado.idparte_trabajo, this.usuarioIdalmacen, this.usuarioIdtecnico)
        this.$appRouter.replace({
          name: 'offline__parte-trabajo-view',
          params: {
            idparte_trabajo: parteCreado.idparte_trabajo,
          },
        })
      } else {
        this.$alert.showSnackbarSuccess(`Se ha creado el parte de trabajo Nº${parteCreado.idparte_trabajo}`)
        this.$appRouter.go(-1)
      }
    },
  },
}
</script>
