<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="sistema"
      :rules="formRules.sistema"
    >
      <b10-autocomplete
        v-model="form.sistema"
        :loading="isLoading"
        :items="sistemas"
        item-text="descripcion"
        label="Sistema"
        clearable
        :error-messages="errors"
        :search-input.sync="sistemaSearch"
        return-object
        no-filter
        :disabled="!!idsistema"
        @change="sistemaSelected"
        @click:clear="clearSistemas"
      >
        <template #item="slotProps">
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ $online.sistema.title(slotProps.item) }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              {{ $online.sistema.subtitle(slotProps.item) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template #append-item>
          <div v-intersect="endIntersect" />
        </template>
      </b10-autocomplete>
    </ValidationProvider>
    <b10-autocomplete-alert
      v-if="form.sistema"
    >
      {{ $online.sistema.title(form.sistema) }}<br>{{ $online.sistema.subtitle(form.sistema) }}
    </b10-autocomplete-alert>
    <ValidationProvider
      v-slot="{ errors }"
      name="idempresa"
      :rules="formRules.idempresa"
    >
      <b10-autocomplete
        v-if="empresas.length > 0"
        v-model="form.idempresa"
        :items="empresas"
        item-value="idempresa"
        item-text="nombre"
        label="Empresa"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idtactuacion"
      :rules="formRules.idtactuacion"
    >
      <b10-autocomplete
        v-model="form.idtactuacion"
        :items="tactuaciones"
        item-value="idtactuacion"
        item-text="tactuacion_descripcion"
        label="Tipo de actuación"
        clearable
        :error-messages="errors"
        return-object
        @change="changeIdtactuacion"
      />
    </ValidationProvider>
    <b10-autocomplete
      v-model="form.idtincidencia_ot"
      :items="tincidenciaOts"
      item-value="idtincidencia_ot"
      item-text="descripcion"
      label="Tipo de incidencia"
      clearable
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="observacion"
      :rules="formRules.observacion"
    >
      <b10-textarea
        v-model="form.observacion"
        label="Comentario"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="idsubsis_afectados"
      :rules="formRules.idsubsis_afectados"
    >
      <b10-autocomplete
        v-model="form.idsubsis_afectados"
        :items="subsistemas"
        item-value="idsubsis"
        item-text="descripcion"
        label="Subsistemas afectados"
        clearable
        :error-messages="errors"
        multiple
        chips
        deletable-chips
      />
    </ValidationProvider>
    <v-checkbox
      v-model="form.agregarMaterialAfectado"
      label="Agregar material afectado de los subsistemas seleccionados"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteTrabajoAddFormData'
import { PARAMETRO } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idsistema: {
      type: [String, Number],
      default: null,
    },
  },
  data () {
    return {
      form: {
        sistema: null,
        observacion: null,
        idtactuacion: null,
        idtincidencia_ot: null,
        idempresa: null,
        idsubsis_afectados: [],
        agregarMaterialAfectado: false,
      },
      formRules: {
        sistema: { required: true },
        observacion: { required: true },
        idtactuacion: { required: true },
        idempresa: { required: true },
        idsubsis_afectados: { required: true },
      },
      sistemas: [],
      tactuaciones: [],
      empresas: [],
      tincidenciaOts: [],
      subsistemas: [],
      sistemaSearch: '',
      isLoading: false,
      page: 0,
      searchValue: '',
    }
  },
  watch: {
    sistemaSearch (value) {
      if (value)
        if (value.length < 3) {
          if (this.page > 0) {
            this.page = 0
          }
        } else {
          if (value !== this.form.sistema){
            this.searchValue = value
            this.sistemaDoSearch(value)
          }
        }
    },
  },
  async created () {
    const parametrosNecesarios = [ PARAMETRO.parametros.EMPRESA_DEFECTO, ]
    const selectLookups = await Data.selectLookups(this, parametrosNecesarios, this.idsistema)
    this.empresas = selectLookups.data.selectEmpresa.result.dataset
    this.tincidenciaOts = selectLookups.data.selectTincidenciaOt.result.dataset
    // empresas
    if (this.empresas.length === 1) {
      this.$set(this.form, 'idempresa', this.empresas[0].idempresa)
    } else {
      const empresaDefecto = _.find(selectLookups.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.EMPRESA_DEFECTO })
      if (empresaDefecto) {
        const existeEmpresaDefecto = _.find(this.empresas, {idempresa: parseInt(empresaDefecto.valor)})
        if (existeEmpresaDefecto) {
          this.$set(this.form, 'idempresa', parseInt(empresaDefecto.valor))
        }
      }
    }
    if (this.idsistema) {
      this.sistemas = selectLookups.data.selectSistema.result.dataset
      if (this.sistemas) {
        this.$set(this.form, 'sistema', this.sistemas[0])
        this.sistemaSelected(this.sistemas[0])
      }
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async sistemaDoSearch (value) {
      this.isLoading = true
      this.sistemas = await Data.selectSistemaSearch(this, value, this.page)
      this.isLoading = false
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.sistemas.length > 0) {
        this.isLoading = true
        this.page += 1
        let masSistemas = await Data.selectSistemaSearch(this, this.searchValue, this.page)
        this.sistemas = [ ...this.sistemas, ...masSistemas]
        this.isLoading = false
      }
    },
    async clearSistemas() {
      this.page = 0
      this.sistemas = []
    },
    async sistemaSelected (value) {
      if (value) {
        const selectLookupsSistema = await Data.selectLookupsSistema(this, value.idsistema, value.idtsistema)
        this.tactuaciones = selectLookupsSistema.data.selectTactuacionTsistema.result.dataset
        this.subsistemas = selectLookupsSistema.data.selectSubsis.result.dataset
        if (this.subsistemas.length === 1) {
          this.$set(this.form, 'idsubsis_afectados', [this.subsistemas[0].idsubsis])
        }
        const idempresa = value.idempresa ? value.idempresa : value.idempresa_cliente
        if (idempresa) {
          const existeEmpresa = _.find(this.empresas, {idempresa: parseInt(idempresa)})
          if (existeEmpresa) {
            this.$set(this.form, 'idempresa', parseInt(idempresa))
          }
        }
      } else {
        this.tactuaciones = []
        this.subsistemas = []
        this.$set(this.form, 'idtactuacion', null)
        this.$set(this.form, 'idsubsis_afectados', [])
      }
    },
    async changeIdtactuacion (value) {
      this.$set(this.form, 'agregarMaterialAfectado', value.incluir_material_al_parte)
      if (!this.form.observacion) {
        this.$set(this.form, 'observacion', value.descripcion)
      } else {
        const res = await this.$alert.showConfirm(
          '¿Quieres sustituir el comentario por la descripción del tipo de actuación?'
        )
        if (res) {
          this.$set(this.form, 'observacion', value.descripcion)
        }
      }
    }
  },
}
</script>
