import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros, idsistema) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectEmpresa', method: 'empresa.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTincidenciaOt', method: 'tincidenciaOt.select', params: { filter: apiFilterEstadoActivo } })
    // Parámetros
    const apiFilterParamertos = new APIFilter()
    apiFilterParamertos.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParamertos } })
    if (idsistema) {
      const apiFilterSistema = new APIFilter()
      apiFilterSistema
        .setOperator(API_FILTER_OPERATORS.AND)
        .addExact('estado', 1)
        .addExact('idsistema', idsistema)
      apiCalls.push({ name: 'selectSistema', method: 'sistema.select', params: { filter: apiFilterSistema } })
    }
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookupsSistema (Vue, idsistema, idtsistema) {
    let apiCalls = []
    const apiFilterTactuacion = new APIFilter()
    apiFilterTactuacion.addGT('estado', 0)
    apiFilterTactuacion.addExact('idtsistema', idtsistema)
    apiFilterTactuacion.addGT('tactuacion_estado', 0)
    const apiFilterSubsis = new APIFilter()
    apiFilterSubsis.addGT('estado', 0)
    apiFilterSubsis.addExact('idsistema', idsistema)
    apiCalls.push({ name: 'selectTactuacionTsistema', method: 'tactuacionTsistema.select', params: { filter: apiFilterTactuacion } })
    apiCalls.push({ name: 'selectSubsis', method: 'subsis.select', params: { filter: apiFilterSubsis } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectSistemaSearch (Vue, search, page) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
    apiFilter.addNestedFilter(Vue.$online.sistema.searchFilter(search))
    const resp = await Vue.$api.call(
      'sistema.select',
      {
        filter: apiFilter,
        page: page,
        page_size: Vue.$api.ITEMS_PER_PAGE_AUTOCOMPLETE,
      },
      {
        disableUI: false,
      },
    )
    return resp.data.result.dataset
  },
}
